import React from 'react';
import { Grid } from '@mui/material';
import ItemCard from './ItemCard';
import { Item, ItemListProps } from '../types';

const ItemList: React.FC<ItemListProps> = ({ items, onItemClick, showWatchlist = false }) => {
  return (
    <Grid container spacing={3}>
      {items.map((item: Item) => (
        <Grid item xs={12} sm={6} md={4} key={item.id}>
          <ItemCard
            item={item}
            onItemClick={onItemClick}
            showWatchlist={showWatchlist}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ItemList;
