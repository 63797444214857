import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getAuctions } from '../services/api';
import ItemList from '../components/ItemList';
import { useNotification } from '../contexts/NotificationContext';
import { Item } from '../types';

const Home: React.FC = () => {
  const { showNotification } = useNotification();
  const { data: items, isLoading, error } = useQuery<Item[]>({
    queryKey: ['auctions'],
    queryFn: getAuctions,
  });

  if (error) {
    showNotification('Error loading auctions', 'error');
    return (
      <Container>
        <Typography color="error">Error loading auctions</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Active Auctions
        </Typography>
        {isLoading ? (
          <Typography>Loading auctions...</Typography>
        ) : (
          <ItemList items={items || []} showWatchlist />
        )}
      </Box>
    </Container>
  );
};

export default Home;
