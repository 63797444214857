import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getWatchlist, removeFromWatchlist } from '../services/api';
import { useNotification } from '../contexts/NotificationContext';
import ItemCard from '../components/ItemCard';
import { WatchlistItemWithItem } from '../types';

const WatchlistPage: React.FC = () => {
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  const { data: watchlistItems, isLoading } = useQuery({
    queryKey: ['watchlist'],
    queryFn: getWatchlist,
  });

  const removeFromWatchlistMutation = useMutation({
    mutationFn: removeFromWatchlist,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['watchlist'] });
      showNotification('Item removed from watchlist', 'success');
    },
    onError: () => {
      showNotification('Failed to remove item from watchlist', 'error');
    },
  });

  if (isLoading) {
    return (
      <Container maxWidth="lg">
        <Typography>Loading watchlist...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          My Watchlist
        </Typography>
        {watchlistItems?.length === 0 ? (
          <Typography>Your watchlist is empty</Typography>
        ) : (
          <Grid container spacing={3}>
            {watchlistItems?.map((watchlistItem: WatchlistItemWithItem) => (
              <Grid item xs={12} sm={6} md={4} key={watchlistItem.itemId}>
                <ItemCard
                  item={watchlistItem.item}
                  showWatchlist
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default WatchlistPage;
