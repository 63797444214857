import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Box,
  Chip,
  CardActionArea,
} from '@mui/material';
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { formatDistanceToNow } from 'date-fns';
import { useAuth } from '../contexts/AuthContext';
import { useNotification } from '../contexts/NotificationContext';
import { Item, ItemCardProps } from '../types';
import { addToWatchlist, removeFromWatchlist, getWatchlist } from '../services/api';

const ItemCard: React.FC<ItemCardProps> = ({ item, onItemClick, showWatchlist = false }) => {
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  const { data: watchlistItems } = useQuery({
    queryKey: ['watchlist'],
    queryFn: getWatchlist,
    enabled: !!user && showWatchlist,
  });

  const isWatched = watchlistItems?.some((watchItem) => watchItem.itemId === item.id) ?? false;

  const addToWatchlistMutation = useMutation({
    mutationFn: () => addToWatchlist(item.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['watchlist'] });
      showNotification('Added to watchlist', 'success');
    },
    onError: () => {
      showNotification('Failed to add to watchlist', 'error');
    },
  });

  const removeFromWatchlistMutation = useMutation({
    mutationFn: () => removeFromWatchlist(item.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['watchlist'] });
      showNotification('Removed from watchlist', 'success');
    },
    onError: () => {
      showNotification('Failed to remove from watchlist', 'error');
    },
  });

  const handleWatchlistClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!user) {
      showNotification('Please login to add items to watchlist', 'info');
      return;
    }

    if (isWatched) {
      removeFromWatchlistMutation.mutate();
    } else {
      addToWatchlistMutation.mutate();
    }
  };

  const handleClick = () => {
    if (onItemClick) {
      onItemClick(item);
    }
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          component="img"
          height="200"
          image={item.imageUrls[0] || '/placeholder-image.svg'}
          alt={item.name}
        />
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Typography gutterBottom variant="h6" component="div">
              {item.name}
            </Typography>
            {showWatchlist && (
              <IconButton
                onClick={handleWatchlistClick}
                color="primary"
                size="small"
                sx={{ mt: -1, mr: -1 }}
              >
                {isWatched ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
            )}
          </Box>
          <Typography variant="body2" color="text.secondary" noWrap>
            {item.description}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" color="primary">
              ${item.currentBid || item.startingBid}
            </Typography>
            <Chip
              label={formatDistanceToNow(new Date(item.endDate), { addSuffix: true })}
              size="small"
              color={new Date(item.endDate) > new Date() ? 'primary' : 'default'}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ItemCard;
