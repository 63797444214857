import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Box,
} from '@mui/material';
import {
  Notifications as NotificationsIcon,
  AccountCircle,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState<null | HTMLElement>(null);
  const [unreadCount] = useState(0);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMobileAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMobileAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      handleClose();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, display: { sm: 'none' } }}
          onClick={handleMobileMenu}
        >
          <MenuIcon />
        </IconButton>

        <Typography
          variant="h6"
          component={RouterLink}
          to="/"
          sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}
        >
          Lucky Auctions
        </Typography>

        <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
          {user ? (
            <>
              <Button color="inherit" component={RouterLink} to="/auctions">
                Auctions
              </Button>
              <IconButton
                color="inherit"
                component={RouterLink}
                to="/notifications"
              >
                <Badge badgeContent={unreadCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </>
          ) : (
            <>
              <Button color="inherit" component={RouterLink} to="/login">
                Login
              </Button>
              <Button color="inherit" component={RouterLink} to="/register">
                Register
              </Button>
            </>
          )}
        </Box>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            component={RouterLink}
            to="/profile"
            onClick={handleClose}
          >
            Profile
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/watchlist"
            onClick={handleClose}
          >
            Watchlist
          </MenuItem>
          {user?.role === 'admin' && (
            <MenuItem
              component={RouterLink}
              to="/admin"
              onClick={handleClose}
            >
              Admin Panel
            </MenuItem>
          )}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>

        <Menu
          id="menu-mobile"
          anchorEl={mobileAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(mobileAnchorEl)}
          onClose={handleClose}
        >
          {user ? (
            [
              <MenuItem
                key="auctions"
                component={RouterLink}
                to="/auctions"
                onClick={handleClose}
              >
                Auctions
              </MenuItem>,
              <MenuItem
                key="notifications"
                component={RouterLink}
                to="/notifications"
                onClick={handleClose}
              >
                Notifications
              </MenuItem>,
              <MenuItem
                key="profile"
                component={RouterLink}
                to="/profile"
                onClick={handleClose}
              >
                Profile
              </MenuItem>,
              <MenuItem
                key="watchlist"
                component={RouterLink}
                to="/watchlist"
                onClick={handleClose}
              >
                Watchlist
              </MenuItem>,
              user.role === 'admin' && (
                <MenuItem
                  key="admin"
                  component={RouterLink}
                  to="/admin"
                  onClick={handleClose}
                >
                  Admin Panel
                </MenuItem>
              ),
              <MenuItem key="logout" onClick={handleLogout}>
                Logout
              </MenuItem>,
            ]
          ) : (
            [
              <MenuItem
                key="login"
                component={RouterLink}
                to="/login"
                onClick={handleClose}
              >
                Login
              </MenuItem>,
              <MenuItem
                key="register"
                component={RouterLink}
                to="/register"
                onClick={handleClose}
              >
                Register
              </MenuItem>,
            ]
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
