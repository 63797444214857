import axios from 'axios';
import { User, Item, Bid, BidWithItem, Notification, WatchlistItem, WatchlistItemWithItem } from '../types';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Auth endpoints
export const login = async (email: string, password: string) => {
  const response = await api.post('/auth/login', { email, password });
  return response.data;
};

export const register = async (username: string, email: string, password: string) => {
  const response = await api.post('/auth/register', { username, email, password });
  return response.data;
};

// User endpoints
export const getUsers = async (): Promise<User[]> => {
  const response = await api.get('/users');
  return response.data;
};

export const updateUser = async (userId: string, data: Partial<User>) => {
  const response = await api.put(`/users/${userId}`, data);
  return response.data;
};

// Item endpoints
export const getAuctions = async (): Promise<Item[]> => {
  const response = await api.get('/items');
  return response.data;
};

export const getItem = async (itemId: string): Promise<Item> => {
  const response = await api.get(`/items/${itemId}`);
  return response.data;
};

export const createItem = async (data: Partial<Item>) => {
  const response = await api.post('/items', data);
  return response.data;
};

export const updateItem = async (itemId: string, data: Partial<Item>) => {
  const response = await api.put(`/items/${itemId}`, data);
  return response.data;
};

// Bid endpoints
export const placeBid = async (itemId: string, amount: number): Promise<Bid> => {
  const response = await api.post(`/items/${itemId}/bids`, { amount });
  return response.data;
};

export const getBids = async (userId: string): Promise<BidWithItem[]> => {
  const response = await api.get(`/users/${userId}/bids`);
  return response.data;
};

export const getItemBids = async (itemId: string): Promise<Bid[]> => {
  const response = await api.get(`/items/${itemId}/bids`);
  return response.data;
};

// Watchlist endpoints
export const getWatchlist = async (): Promise<WatchlistItemWithItem[]> => {
  const response = await api.get('/watchlist');
  return response.data;
};

export const addToWatchlist = async (itemId: string): Promise<WatchlistItem> => {
  const response = await api.post('/watchlist', { itemId });
  return response.data;
};

export const removeFromWatchlist = async (itemId: string): Promise<void> => {
  await api.delete(`/watchlist/${itemId}`);
};

// Notification endpoints
export const getNotifications = async (): Promise<Notification[]> => {
  const response = await api.get('/notifications');
  return response.data;
};

export const markNotificationAsRead = async (notificationId: string) => {
  const response = await api.put(`/notifications/${notificationId}/read`);
  return response.data;
};

// Add auth interceptor
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
