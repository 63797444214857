import React from 'react';
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
  IconButton,
  ListItemSecondaryAction,
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getNotifications, markNotificationAsRead } from '../services/api';
import { useNotification } from '../contexts/NotificationContext';
import { Notification } from '../types';

const NotificationsPage: React.FC = () => {
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  const { data: notifications, isLoading } = useQuery({
    queryKey: ['notifications'],
    queryFn: getNotifications,
  });

  const markAsReadMutation = useMutation({
    mutationFn: markNotificationAsRead,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
      showNotification('Notification marked as read', 'success');
    },
    onError: () => {
      showNotification('Failed to mark notification as read', 'error');
    },
  });

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography>Loading notifications...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper>
        <List>
          {notifications?.length === 0 ? (
            <ListItem>
              <ListItemText primary="No notifications" />
            </ListItem>
          ) : (
            notifications?.map((notification: Notification) => (
              <ListItem
                key={notification.id}
                sx={{
                  backgroundColor: notification.read ? 'inherit' : 'action.hover',
                }}
              >
                <ListItemText
                  primary={notification.title}
                  secondary={notification.message}
                />
                {!notification.read && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="mark as read"
                      onClick={() => markAsReadMutation.mutate(notification.id)}
                    >
                      <CheckIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))
          )}
        </List>
      </Paper>
    </Container>
  );
};

export default NotificationsPage;
