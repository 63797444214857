import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Tab,
  Tabs,
  Grid,
  Paper,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../contexts/AuthContext';
import { useNotification } from '../contexts/NotificationContext';
import { getBids, getWatchlist } from '../services/api';
import ItemCard from '../components/ItemCard';
import { BidWithItem, WatchlistItemWithItem } from '../types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

const ProfilePage: React.FC = () => {
  const [value, setValue] = useState(0);
  const { user } = useAuth();
  const { showNotification } = useNotification();

  const { data: bids, isLoading: bidsLoading } = useQuery({
    queryKey: ['bids', user?.id],
    queryFn: () => getBids(user?.id || ''),
    enabled: !!user,
  });

  const { data: watchlistItems, isLoading: watchlistLoading } = useQuery({
    queryKey: ['watchlist'],
    queryFn: getWatchlist,
    enabled: !!user,
  });

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!user) {
    return (
      <Container>
        <Typography>Please log in to view your profile</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="profile tabs"
          >
            <Tab label="Profile Info" {...a11yProps(0)} />
            <Tab label="My Bids" {...a11yProps(1)} />
            <Tab label="Watchlist" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Typography variant="h6">Profile Information</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography>Username: {user.username}</Typography>
            <Typography>Email: {user.email}</Typography>
            <Typography>Role: {user.role}</Typography>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          {bidsLoading ? (
            <Typography>Loading bids...</Typography>
          ) : bids?.length === 0 ? (
            <Typography>No bids placed yet</Typography>
          ) : (
            <Grid container spacing={3}>
              {bids?.map((bid: BidWithItem) => (
                <Grid item xs={12} sm={6} md={4} key={bid.id}>
                  <ItemCard
                    item={bid.item}
                    showWatchlist
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </TabPanel>

        <TabPanel value={value} index={2}>
          {watchlistLoading ? (
            <Typography>Loading watchlist...</Typography>
          ) : watchlistItems?.length === 0 ? (
            <Typography>Your watchlist is empty</Typography>
          ) : (
            <Grid container spacing={3}>
              {watchlistItems?.map((watchlistItem: WatchlistItemWithItem) => (
                <Grid item xs={12} sm={6} md={4} key={watchlistItem.itemId}>
                  <ItemCard
                    item={watchlistItem.item}
                    showWatchlist
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default ProfilePage;
