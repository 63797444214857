import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Paper,
  Container,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getUsers, getAuctions } from '../services/api';
import ItemList from '../components/ItemList';
import { useNotification } from '../contexts/NotificationContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `admin-tab-${index}`,
    'aria-controls': `admin-tabpanel-${index}`,
  };
}

const AdminPanel: React.FC = () => {
  const [value, setValue] = useState(0);
  const { showNotification } = useNotification();

  const { data: users, isLoading: usersLoading, error: usersError } = useQuery({
    queryKey: ['users'],
    queryFn: getUsers,
  });

  const { data: auctions, isLoading: auctionsLoading, error: auctionsError } = useQuery({
    queryKey: ['auctions'],
    queryFn: getAuctions,
  });

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (usersError || auctionsError) {
    showNotification('Error loading data', 'error');
    return <Typography color="error">Error loading data</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="admin panel tabs"
          >
            <Tab label="Users" {...a11yProps(0)} />
            <Tab label="Auctions" {...a11yProps(1)} />
            <Tab label="Reports" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          {usersLoading ? (
            <Typography>Loading users...</Typography>
          ) : (
            <Typography>Users panel content</Typography>
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {auctionsLoading ? (
            <Typography>Loading auctions...</Typography>
          ) : (
            <ItemList items={auctions || []} />
          )}
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Typography>Reports and analytics will be shown here</Typography>
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default AdminPanel;
